<template>
  <!-- 直播指引组件 -->
  <div class="Box">
       <div class="topTitle">
            <h4>直播指引</h4>
            <!-- //内容盒子 -->
            <div class="listBox">
                <div class="OneBox">
                    <i class="el-icon-warning"></i>
                    <span>请确保您的电脑已下载直播工具“obs Studio ”，否则将无法开展直播！</span>
                </div>
                <div class="tb">
                    <span> 开展直播需使用第三方工具 </span>
                        <div>
                            <img src="../../assets/images/playLogo.png" alt="">
                        </div>
                    <span> ，否则您开展的直播将无画面显示。请根据您的实际情况，查看以下指引开展直播！ </span>
                </div>
                <p>①未下载直播工具，

                    <span @click="toGuide(1)">查看下载指引</span> 

                </p> 
                <p>②已下载直播工具，

                    <span @click="toGuide(2)">查看使用指引</span>

                </p> 
                <!-- 推流信息 -->
                <!-- <div class="Info">
                    <h4>推流信息：</h4>
                    <p>
                        <span>服务器：</span>XXX
                        <span>密钥：</span>XXX
                    </p>
                </div> -->
                
            </div>
       </div>
  </div>
</template>

<script>
export default {
   data(){
       return{

       }
   },
   methods:{
       toGuide(type){
        //type=1跳转显示下载指引，2跳转显示使用指引
          this.$router.push({
              path:'/liveGuideDetail',
              query:{
                  id:type
              }
          })
       }
   }
}
</script>

<style lang="less" scoped>
    .Box{
        padding-top: 24px;
        //标题
        .topTitle{
            // display: flex;
            background: #ffffff;
            border-radius: 6px;

             h4{
                    font-size: 22px;
                     font-weight: 700;
                     padding-left: 20px;
                     padding-top: 24px;
            }
            //内容
            .listBox{
                // margin-top: 20px;
                height: 170px;
                padding: 20px 20px;
                p{
                    margin-bottom: 10px;
                    span{
                        color: #3489fe;
                        cursor: pointer;
                    }
                }
                .OneBox{
                    i{
                        margin-right: 8px;
                        color: #FF8201;
                    }
                    // width: 1332px;
                    height: 32px;
                    background: rgba(255,130,1,0.05);
                    color: #FF8201;
                    display: flex;
                    align-items: center;
                    border-radius: 4px;
                    padding: 4px;
                }
                //图标
                .tb{
                    display: flex;
                    align-items: center;
                    div{
                        width: 40px;
                        height: 40px;
                        img{
                            width: 100%;
                        }
                    }
                }
                //推流信息
                .Info{
                    display: flex;
                    align-items: center;
                    margin-top: 20px;
                    p{
                        margin-bottom: 0  !important;
                        span{
                            color:#999999 ;
                        }
                    }
                }
            }
        }
    }
</style>